<template>
  <div @click="isShow = -1" id="personage">
    <div class="personageItem">
      <div class="title">
        <p>
          <span class="backG">教育背景</span>
          <Button v-throttleClick  class="add" @click="editItem(newList1,1)">添加&nbsp;<Icon type="md-add" /></Button>
          <span class="xiaoshou" :style="newList1.auditStatus | colored">{{newList1.auditStatus | type}}</span>
          <Icon v-show="newList1.auditStatus == 2" @click.stop="cause(1)" class="yanjing" type="md-eye" size="16" />
          <span @click.stop="isShow = 1" v-show="isShow == 1" class="cause">
            <span style="display:block;textAlign: left; color:#f32048">未通过原因：</span>
            <span>{{newList1.reason}}</span>
          </span>   
        </p>
      </div>
      <ul class="list">
        <li class="listItem">
          <div class="itemBox">
            <p>
              <span v-if="newList1.content" class="school">{{ newList1.content }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="personageItem">
      <div class="title">
        <p>
          <span class="backG">工作经历</span>
          <Button v-throttleClick class="add" @click="editItem(newList2,2)">添加&nbsp;<Icon type="md-add" /></Button>
          <span class="xiaoshou" :style="newList2.auditStatus | colored">{{newList2.auditStatus | type}}</span>
          <Icon v-show="newList2.auditStatus == 2" @click.stop="cause(2)" class="yanjing" type="md-eye" />
          <span @click.stop="isShow = 2" v-show="isShow == 2" class="cause">
            <span style="display:block;textAlign: left; color:#f32048">未通过原因：</span>
            <span>{{newList2.reason}}</span>
          </span> 
        </p>
      </div>
      <ul class="list">
        <li class="listItem">
          <div class="itemBox">
            <p>
              <span v-if="newList2.content" class="school">{{ newList2.content }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="personageItem">
      <div class="title">
        <p>
          <span class="backG">职称经历</span>
          <Button v-throttleClick class="add" @click="editItem(newList3,3)">添加&nbsp;<Icon type="md-add" /></Button>
          <span class="xiaoshou" :style="newList3.auditStatus | colored">{{newList3.auditStatus | type}}</span>
          <Icon v-show="newList3.auditStatus == 2" @click.stop="cause(3)" class="yanjing" type="md-eye" />
          <span @click.stop="isShow = 3" v-show="isShow == 3" class="cause">
            <span style="display:block;textAlign: left; color:#f32048">未通过原因：</span>
            <span>{{newList3.reason}}</span>
          </span> 
        </p>
      </div>
      <ul class="list">
        <li class="listItem">
          <div class="itemBox">
            <p>
              <span v-if="newList3.content" class="school">{{ newList3.content }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="personageItem">
      <div class="title">
        <p>
          <span class="backG">社会任职</span>
          <Button v-throttleClick class="add" @click="editItem(newList4,4)">添加&nbsp;<Icon type="md-add" /></Button>
          <span class="xiaoshou" :style="newList4.auditStatus | colored">{{newList4.auditStatus | type}}</span>
          <Icon v-show="newList4.auditStatus == 2" @click.stop="cause(4)" class="yanjing" type="md-eye" />
          <span @click.stop="isShow = 4" v-show="isShow == 4" class="cause">
            <span style="display:block;textAlign: left; color:#f32048">未通过原因：</span>
            <span>{{newList4.reason}}</span>
          </span> 
        </p>
      </div>
      <ul class="list">
        <li class="listItem">
          <div class="itemBox">
            <p>
              <span v-if="newList4.content" class="school">{{ newList4.content }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <Modal
      :scrollable="true"
      v-model="alert"
      width="572px"
      class-name="vertical-center-modal"
      :footer-hide="true"
    >
      <div class="alert">
        <template v-if="type == 6">
          <div class="title">教育背景</div>
          <!-- <div class="titleItem">院校名称</div>
          <Input
            v-model="eduExperiencesName"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
            placeholder=""
          />
          <div class="titleItem">专业名称</div>
          <Input
            v-model="eduExperiencesZYName"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
            placeholder=""
          />
          <div class="titleItem">学历</div>
          <Select
            v-model="eduExperiencesxueli"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
          >
            <Option value="专科">专科</Option>
            <Option value="本科">本科</Option>
            <Option value="硕士研究生">硕士研究生</Option>
            <Option value="博士研究生">博士研究生</Option>
          </Select>
          <div class="titleItem">时间</div>
          <DatePicker
            @on-change="handleChange1"
            format="yyyy/MM/dd"
            type="daterange"
            :value="eduExperiencesTime"
            key="1"
            placement="bottom-end"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
          ></DatePicker> -->
        <textarea maxlength="2000" style="fontSize:15px" v-model="newValue" placeholder='请输入教育背景 ' class="alert1" name="message" rows="10" cols="61">
        
        </textarea>
        </template>
        <template v-if="type == 9">
          <div class="title">工作经历</div>
          <!-- <div class="titleItem">单位/院校名称</div>
          <Input
            v-model="workName"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
            placeholder=""
          />
          <div class="titleItem">职位名称</div>
          <Input
            v-model="workJob"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
            placeholder=""
          />

          <div class="titleItem">时间</div>
          <DatePicker
            @on-change="handleChange1"
            format="yyyy/MM/dd"
            type="daterange"
            :value="workTime"
            key="2"
            placement="bottom-end"
            placeholder="Select date"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
          ></DatePicker> -->
        <textarea maxlength="2000" style="fontSize:15px" v-model="newValue" placeholder='请输入工作经历 ' class="alert1" name="message" rows="10" cols="61">
        
        </textarea>
        </template>
        <template v-if="type == 7">
          <div class="title">职称经历</div>
          <!-- <div class="titleItem">职位名称</div>
          <div class="box">
            <Select
              v-model="value1"
              style="width: 150px; margin-left: 57px; margin-top: 20px"
            >
              <Option
                v-for="(item, index) in historyList1"
                :key="index"
                :value="JSON.stringify(item)"
              >
                {{ item.seriesName }}
              </Option>
            </Select>
            <Select
              @click.native="two"
              v-model="value2"
              style="width: 150px; margin-top: 20px"
              @on-change="handerChange"
            >
              <Option
                v-for="(item, index) in historyList2"
                :key="index"
                :value="JSON.stringify(item)"
              >
                {{ item.gradeName }}
              </Option>
            </Select>
            <Select v-model="value3" style="width: 150px; margin-top: 20px">
              <Option
                v-for="(item, index) in historyList3"
                :key="index"
                :value="JSON.stringify(item)"
              >
                {{ item.titleName }}
              </Option>
            </Select>
          </div>
          <div class="titleItem">时间</div>
          <DatePicker
            type="date"
            :value="historyDate"
            key="3"
            @on-change="handleChange1"
            placeholder="Select date"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
          ></DatePicker> -->
        <textarea maxlength="2000" style="fontSize:15px" v-model="newValue" placeholder='请输入职称经历 ' class="alert1" name="message" rows="10" cols="61">
        
        </textarea>
        </template>
        <template v-if="type == 8">
          <div class="title">社会任职</div>
          <!-- <div class="titleItem">单位/院校名称</div>
          <Input
            v-model="jzName"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
            placeholder=""
          />
          <div class="titleItem">职位名称</div>
          <Input
            v-model="jzJob"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
            placeholder=""
          />

          <div class="titleItem">时间</div>
          <DatePicker
            key="4"
            :value="jzTime"
            @on-change="handleChange1"
            format="yyyy/MM/dd"
            type="daterange"
            placement="bottom-end"
            placeholder="Select date"
            style="width: 462px; margin-left: 57px; margin-top: 20px"
          ></DatePicker> -->
         <textarea maxlength="2000" style="fontSize:15px" v-model="newValue" placeholder='请输入社会任职 ' class="alert1" name="message" rows="10" cols="61">
        
        </textarea>
        </template>

        <div class="btn">
          <div @click="closeAlert" class="close">取消</div>
          <Button style="border: none;outline: none;" v-throttleClick @click="affirm" class="affirm">提交</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "personage",
  created() {
    this.getData();
  },
  data() {
    return {
      newValue:'', //个人履历内容
      newList1:{}, //教育背景
      newList2:{}, // 工作经历
      newList3:{}, //职称经历
      newList4:{}, //社会任职
      id:'', //id
      type:null, //类型
      alert:false, //编辑框显影
      isShow:-1
    };
  },
  filters: {
    date: function (time) {
      time = new Date(time).getTime();
      let year = new Date(time).getFullYear();
      let month = new Date(time).getMonth() + 1;
      month < 10 ? (month = `0${month}`) : month;
      let day = new Date(time).getDate();
      day < 10 ? (day = `0${day}`) : day;
      // console.log(day);
      // console.log(new Date(time).getTime());
      return `${year}-${month}-${day}`;
    },
    type(num) {
      if (num === 0) return `审核中`;
      if (num === 1) return `通过`;
      if (num === 2) return `未通过`;
    },
    colored(num) {
      if (num === 2) return "color:#fff;background:#F32048";
      if (num === 0) return "color:#fff;background:#f1c40f";
      if (num === 1) return "color:#fff;background:#2ecc71";
    },
  },
  methods: {
    // 获取个人履历
    async getData() {
      this.newList1 = {}
      this.newList2 = {}
      this.newList3 = {}
      this.newList4 = {}
      let { data } = await this.$apis.userServe.getexperience();
      console.log('获取的最新数据：',data)
      data.forEach(item => {
        if(item.type == 6){
          this.newList1 = item
          console.log('教育背景',this.newList1)
        }
        if(item.type == 9){
          this.newList2 = item
          console.log('工作经历',this.newList2)
        }
        if(item.type == 7){
          this.newList3 = item
          console.log('职称经历',this.newList3)
        }
        if(item.type == 8){
          this.newList4 = item
          console.log('社会任职',this.newList4)
        }
      });
    },
    // 编辑按钮
    editItem(item,type) {
      this.newValue = ''
      this.id = ''
      this.type = ''
      console.log('编辑按钮:',item)
      if (item.id && !(item.auditStatus == 1 || item.auditStatus == 2)) {
        this.$Message.error("信息审核中");
        this.alert = false;
        return;
      }else if ((item.auditStatus == 2 || item.auditStatus == 1) && item.auditStatus !== 0) {
        this.alert = true
        switch (type) {
          case 1:
            console.log('修改教育背景')
            this.newValue = item.content
            this.id = item.id
            this.type = item.type
            break;
          case 2:
            console.log('修改工作经历')
            this.newValue = item.content
            this.id = item.id
            this.type = item.type
            break;
          case 3:
            console.log('修改职称经历')
            this.newValue = item.content
            this.id = item.id
            this.type = item.type
            break;
          case 4:
            console.log('修改社会任职')
            this.newValue = item.content
            this.id = item.id
            this.type = item.type
            break;
        }
      }else{
        this.alert = true
        switch (type) {
          case 1:
            console.log('添加教育背景')
            this.type = 6
            break;
          case 2:
            console.log('添加工作经历')
            this.type = 9
            break;
          case 3:
            console.log('添加职称经历')
            this.type = 7
            break;
          case 4:
            console.log('添加社会任职')
            this.type = 8
            break;
        }
      }
    },
    closeAlert() {
      this.alert = false;
    },
    // 封装履历编辑请求规则及请求接口
    async regulation(obj){
      let {newValue} = this
      console.log('请求参数:',obj)
      const f1 = /\s\S+|S+\s|\S/;//空白符和字符串，字符串
      let f2 = /<(?:[^"'>]|(["'])[^"']*\1)*>/g; //匹配HTML标签
      if (!f1.test(newValue) && !this.id) {
        this.$Message.error('不能为空')
        return
      }
      if (f2.test(newValue) && !this.id){
        this.$Message.error('不合法')
        return
      }
      let res = await this.$apis.userServe.addOrEditExperience(obj);
      console.log('是否成功?',res)
      if(res.status == 200){
        this.$Message.success('提交成功')
        this.alert = false
      }else{
        this.$Message.error(res.resultDesc)
      }
    },
    // 定义删除函数
   async listDelete(){
      let obj = {
        content: this.newValue,
        type: this.type,
        id:this.id
      }
      await this.regulation(obj)
      await this.getData() //刷新数据
      this.alert = false
    },
    // 个人履历确认提交
    async affirm() {
      if (!this.id) {
        console.log('要添加')
        //准备数据
        let obj = {
          content: this.newValue, 
          type: this.type, 
        }
        await this.regulation(obj)
        if(!this.newValue) return
        await this.getData() //刷新数据
        return
      }else if(this.id && this.newValue){
        console.log('要修改')
          let obj = {
            content: this.newValue, 
            type: this.type, 
            id:this.id
          }
        await this.regulation(obj)
        await this.getData() //刷新数据
        return
      }
      switch (this.type) {
        case 6:
          if(!this.newValue && this.newList1){
            console.log('背景要删除')
            this.listDelete()
          }
          break;
        case 9:
          if(!this.newValue && this.newList2){
            this.listDelete()
          }
          break;
        case 7:
          if(!this.newValue && this.newList3){
            this.listDelete()
          }
          break;
        case 8:
          if(!this.newValue && this.newList4){
            this.listDelete()
          }
          break;
      }
    },
    // 不通过原因
    cause(id){
      switch (id) {
        case 1:
          this.isShow = this.isShow -1 ? 1 : -1
          break;
        case 2:
          this.isShow = this.isShow -2 ? 2 : -1
          break;
        case 3:
          this.isShow = this.isShow -3 ? 3 : -1
          break;
        case 4:
          this.isShow = this.isShow -4 ? 4 : -1
          break;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.alert {
  .alert1{
    margin-top: 25px;
    margin-left: 15px;
  }
  .btn {
    margin-top: 34px;
    width: 100%;
    // height: 120px;
    //background: pink;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      text-align: center;
      line-height: 38px;
      width: 72px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      margin-right: 13px;
      cursor: pointer;
    }

    .affirm {
      margin-left: 13px;
      text-align: center;
      line-height: 38px;
      width: 70px;
      height: 36px;
      color: #fff;
      background: #007fff;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .title {
    margin-left: 15px;
    margin-top: 24px;
    height: 25px;
    font-size: 24px;
    //background: yellow;
    font-family: Microsoft YaHei;
    font-weight: 900;
    color: #333333;
    line-height: 24px;
  }

  .titleItem {
    margin-left: 77px;
    margin-top: 50px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    height: 25px;
    font-size: 24px;
  }
}

#personage {
  .personageItem {
    width: 1200px;
    min-height: 150px;
    // padding-bottom: 32px;
    background: #ffffff;
    // box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
    // margin-top: 12px;
    padding:32px 0 1px 0;
    box-sizing: content-box;
    .list {
      margin-top: 28px;
      //background: #e5e5e5;
      // .listItem:hover {
      //   background: #e5e5e5;
      //   color: #333;
      //   opacity: 0.32;

      //   .edit {
      //     display: block;
      //     img {
      //       cursor: pointer;
      //     }
      //   }
      // }

      .listItem {
        color: #333;
        // padding-top: 23px;
        width: 1200px;
        // height: 100px;
        padding-left: 40px;
        //background: #fff;
        border-radius: 10px;
        position: relative;
        margin: 0 auto;
        .itemBox {
          margin-bottom: 10px;
          .school{
            max-width: 900px;
            white-space: pre-wrap	;
            line-height: 40px;
          }
          p > span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            display: inline-block;
          }
        }

        .edit {
          display: none;
          position: absolute;
          right: 64px;
          height: 100%;
          bottom: 0;
          background: #e5e5e5;
          padding-top: 12px;
          img {
            margin-left: 20px;
            width: 17px;
            height: 18px;
          }
        }
      }
    }

    .box {
      //display: flex;
    }

    .title {
      margin-left: 37px;
      height: 22px;
      .backG {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }

      .add {
        margin-left: 15px;
        // height: 22px;
        cursor: pointer;
        text-align: center;
        line-height: 100%;
        // line-height: 22px;
        //background: pink;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00A4FF;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 4px 10px 4px 10px;
        border: 1px dashed #00A4FF;
        
      }
      .xiaoshou {
        margin-left: 20px;
        padding: 3px;
        border-radius: 5px;
        font-size: 13px;
      }
      .yanjing{
        cursor: pointer;
        margin-left: 10px;
        color: #F32048;
      }
      .cause{
        display: block;
        max-width: 400px;
        max-height: 150px;
        overflow: auto;
        background: #fff;
        position: relative;
        top: -5px;
        left: 235px;
        box-shadow: 0px 0px 5px #000000;
        z-index: 999;
        padding: 15px;
        word-wrap:break-word;
      }
      //margin-top: 32px;
    }
  }
}
</style>
